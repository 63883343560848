import { AxiosResponse } from 'axios';
import instance from './request';

const baseURL = 'https://podo-test.great-supper.com/podo_new/';

export const getStudentListByName = <T>(data: { ydrID: string; studentName: string }): Promise<AxiosResponse<T>> => {
  return instance.post('/flows/trigger/21e626ac-1afe-460c-b135-97fa4ca667d4', {
    ...data,
  });
};

export const getStudentListById = <T>(data: { ydrID: string; fruitID: number }): Promise<AxiosResponse<T>> => {
  return instance.post('/flows/trigger/21e626ac-1afe-460c-b135-97fa4ca667d4', {
    ...data,
  });
};

// 更换引导人
export const replaceYdr = <T>(data: {
  id: string;
  fruitID: string;
  ApproverID: string;
  ApplicantID: string;
  ApprovalType: string;
  ApprovalStatus: 'Pending' | 'Approved' | 'Rejected' | 'Revoked';
  studentName: string;
}): Promise<AxiosResponse<T>> => {
  // return instance.post('/flows/trigger/a0bffdc4-313e-411f-8133-841bc12c8555', {
  return instance.post('/flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb', {
    ...data,
  });
};

// 更换引导人申请
export const updateYdrApply = <T>({
  id,
  fruitID,
  studentName,
  ApproverID,
  ApplicantID,
  ApprovalType,
  ApprovalStatus,
}: {
  id: string;
  fruitID: string;
  studentName: string;
  ApproverID: string;
  ApplicantID: string;
  ApprovalType: string;
  ApprovalStatus?: string;
}): Promise<AxiosResponse<T>> => {
  return instance({
    // url: `${baseURL}flows/trigger/a0bffdc4-313e-411f-8133-841bc12c8555`,
    url: `${baseURL}flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb`,
    method: 'post',
    data: {
      id,
      fruitID,
      studentName,
      ApproverID,
      ApplicantID,
      ApprovalStatus,
      ApprovalType,
    },
  });
};

// 发出的引导人申请列表, YDRCHANGE
export const getAppliedYDRListByUserId = <T>({
  id,
  ApprovalType,
}: {
  id: string;
  ApprovalType: string;
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?ApplicantID=${id}&ApprovalType=${ApprovalType}`,
    method: 'get',
  });
};

// undefined
export const getAppliedStudentListByUserId = <T>({
  id,
  ApprovalType,
}: {
  id: string;
  ApprovalType: string;
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?ApplicantID=${id}&ApprovalType=${ApprovalType}`,
    method: 'get',
  });
};

export const getAppliedStudentListByApproverId = <T>({
  id,
  ApprovalType,
}: {
  id: string;
  ApprovalType: string;
}): Promise<AxiosResponse<T>> => {
  return instance({
    // ApprovalType 有多个选择的话使用 filter 用法。
    // url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?ApproverID=${id}&filter={"ApprovalType": ["undefined"]}`,
    // url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?ApproverID=${id}&ApprovalType=undefined`,
    url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?ApproverID=${id}&ApprovalType=${ApprovalType}`,
    method: 'get',
  });
};

export const getAppliedStudentListByStudentId = <T>({ id }: { id: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=StudentClass&filter={"studentID":["${id}"]}`,
    method: 'get',
  });
};

export const getAppliedStudentListByTeacherId = <T>({ id }: { id: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=StudentClass&filter={"teacherCode":["${id}"]}`,
    method: 'get',
  });
};

export const updateStudentClass = <T>({
  studentID,
  studentName,
}: {
  studentID: string | number;
  studentName: string | number;
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/6f8b6c03-2ddb-46c8-8eb8-da6a41e0dba5`,
    method: 'post',
    data: {
      tableName: 'StudentClass',
      fields: {
        studentID,
        studentName,
      },
    },
  });
};

// 更新物料21项名称时调用
export const updateFruitsName = <T>({
  fruitID,
  fruitName,
}: {
  fruitID: string | number;
  fruitName: string | number;
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/45147ced-157b-499c-8b1d-a4c77dca68cf`,
    method: 'post',
    data: {
      tableName: 'StudentClass',
      fields: {
        fruitID,
        fruitName,
      },
    },
  });
};

export const getStudentInfoListByUserId = <T>({ id }: { id: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=StudentAchives&filter={"ydrID":["${id}"]}`,
    method: 'get',
  });
};

export const getWeeklyReportByUserId = <T>({ id }: { id: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=LearningReportsHis&filter={"teacherCode":["${id}"]}`,
    method: 'get',
  });
};

export const getWeeklyReportByStudentId = <T>({ id }: { id: number }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=LearningReportsHis&filter={"studentID":[${id}]}`,
    method: 'get',
  });
};

export const getApplyClassList = <T>({ id }: { id: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=Enrollments&filter={"ydrID":["${id}"]}`,
    method: 'get',
  });
};
export const getClassList = <T>(): Promise<AxiosResponse<T>> => {
  return instance({
    // url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=ClassList&filter={"classType":["快速班级", "正常班级", "会属班级","稳健班级", "初级班型", "初级正常班级"],"classStatus":["报名"], "valid": [true]}`,
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=ClassList&filter={"classType":["稳健班级","正常班级","快速班级"],"classStatus":["报名"], "valid": [true]}&sort=-id&limit=10`,
    method: 'get',
  });
};

export const getStudentAchivesByUserId = <T>({ id }: { id: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=StudentAchives&filter={"ydrID":["${id}"]}`,
    method: 'get',
  });
};
export const getStudentAchivesByTeacherId = <T>({ id }: { id: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=StudentAchives&filter={"teacherCode":["${id}"]}`,
    method: 'get',
  });
};

export const getPrimartyClassList = <T>(): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=ClassList&filter={"classType":["初级快速班级", "初级正常班级", "初级班型"], "classStatus": ["报名"], "valid": [true]}`,
    method: 'get',
  });
};

export const getUpgradeTestTime = <T>(): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363?tableName=XTTimetable&filter={"valid":[true], "xtType": ["升初"]}`,
    method: 'get',
  });
};

export const getYDRchangeApplyById = <T>(fruitID: string, ApproverID: string): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?fruitID=${fruitID}&ApproverID=${ApproverID}&ApprovalType=YDRCHANGE`,
    method: 'get',
  });
};

export type ClassApplyType = {
  studentID: string;
  studentName: string;
  contactStatus: string;
  ydrID: string;
  klassName: string;
  askFFSteadyKlass: string;
  insertFFReason: string;
  guideContact: string;
  guideWechat: string;
  guidePhone: string;
  guideLark: string;
  GGTel: string;
  studentHomeTown: string;
  district: string;
  Interviewer: string;
  age: string;
  confirmFile: string;
  confirmStudy: string;
  exception: string;
  exceptionCode: string;
  studentWechat: string;
  notes: string;
  health: string;
  illness: string;
  studentEducational: string;
  studyByNet: string;
  sgFrom: string;
  religionYear: string;
  human: string;
  fearestGod: string;
  gender: string;
  ppCensor: string;
  readAndWrite: string;
  readAndWriteDetail: string;
  otherYdr: string;
  otherYdrWechat: string;
  studentOccupation: string;
  marriage: string;
  bb: string;
  meetings: string;
  studyYet: string;
  learningExperience: string;
  failed: string;
};

// 创建大班申请
export const createClassApply = <T>({
  studentID,
  studentName,
  ydrID,
  klassName,
  guideContact,
  guideWechat,
  guidePhone,
  guideLark,
  insertFFReason,
  GGTel,
  Interviewer,
  age,
  confirmFile,
  confirmStudy,
  exception,
  exceptionCode,
  studentWechat,
  notes,
  health,
  illness,
  studentEducational,
  studyByNet,
  sgFrom,
  religionYear,
  human,
  fearestGod,
  gender,
  ppCensor,
  readAndWrite,
  readAndWriteDetail,
  otherYdr,
  otherYdrWechat,
  studentOccupation,
  marriage,
  bb,
  meetings,
  studyYet,
  learningExperience,
  failed,
  district,
  studentHomeTown,
}: ClassApplyType): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/6f8b6c03-2ddb-46c8-8eb8-da6a41e0dba5`,
    method: 'post',
    data: {
      tableName: 'Enrollments',
      fields: {
        district,
        studentID,
        studentName,
        ydrID,
        klassName,
        guideContact,
        guideWechat,
        guidePhone,
        guideLark,
        insertFFReason,
        GGTel,
        Interviewer,
        age,
        confirmFile,
        confirmStudy,
        exception,
        exceptionCode,
        studentWechat,
        notes,
        health,
        illness,
        studentEducational,
        studyByNet,
        sgFrom,
        religionYear,
        human,
        fearestGod,
        gender,
        ppCensor,
        readAndWrite,
        readAndWriteDetail,
        otherYdr,
        otherYdrWechat,
        studentOccupation,
        marriage,
        bb,
        meetings,
        studyYet,
        learningExperience,
        failed,
        studentHomeTown,
      },
    },
  });
};

// 更新大班申请
export const updateClassApply = <T>({
  studentID,
  studentName,
  ydrID,
  klassName,
  guideContact,
  guideWechat,
  guidePhone,
  guideLark,
  insertFFReason,
  GGTel,
  Interviewer,
  age,
  confirmFile,
  confirmStudy,
  exception,
  exceptionCode,
  studentWechat,
  notes,
  health,
  illness,
  studentEducational,
  studyByNet,
  sgFrom,
  religionYear,
  human,
  fearestGod,
  gender,
  ppCensor,
  readAndWrite,
  readAndWriteDetail,
  otherYdr,
  otherYdrWechat,
  studentOccupation,
  marriage,
  bb,
  meetings,
  studyYet,
  learningExperience,
  failed,
  district,
  studentHomeTown,
}: ClassApplyType): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/0090309b-c365-4e37-acab-8e6e7c5ad7d9`,
    method: 'post',
    data: {
      tableName: 'Enrollments',
      require: {
        studentID,
      },
      fields: {
        district,
        studentName,
        ydrID,
        klassName,
        guideContact,
        guideWechat,
        guidePhone,
        guideLark,
        insertFFReason,
        GGTel,
        Interviewer,
        age,
        confirmFile,
        confirmStudy,
        exception,
        exceptionCode,
        studentWechat,
        notes,
        health,
        illness,
        studentEducational,
        studyByNet,
        sgFrom,
        religionYear,
        human,
        fearestGod,
        gender,
        ppCensor,
        readAndWrite,
        readAndWriteDetail,
        otherYdr,
        otherYdrWechat,
        studentOccupation,
        marriage,
        bb,
        meetings,
        studyYet,
        learningExperience,
        failed,
        studentHomeTown,
      },
    },
  });
};

export type UpdateWeeklyReportType = {
  studentID: string;
  studentName: string;
  lessonCount: string;
  latestLesson: string;
  teachingTools: string;
  lang: string;
  studentCountry: string;
  studentHS: string;
  studentStatus: string;
  teacherCode: string;
  ydrID: string;
  missingReason: string;
  fallReason: string;
  latestLessonSelect: string;
};

// 更新个教周报
export const updateWeeklyReport = <T>({
  studentID,
  studentName,
  lessonCount,
  latestLesson,
  teachingTools,
  lang,
  studentCountry,
  studentHS,
  studentStatus,
  teacherCode,
  ydrID,
  missingReason,
  fallReason,
  latestLessonSelect,
}: UpdateWeeklyReportType): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/6f8b6c03-2ddb-46c8-8eb8-da6a41e0dba5`,
    method: 'post',
    data: {
      tableName: 'LearningReportsHis',
      fields: {
        studentID,
        studentName,
        lessonCount,
        latestLesson,
        teachingTools,
        lang,
        studentCountry,
        studentHS,
        studentStatus,
        teacherCode,
        ydrID,
        missingReason,
        fallReason,
        latestLessonSelect,
      },
    },
  });
};

// 更换个教申请
export const updateTutorApply = <T>({
  id,
  fruitID,
  studentName,
  ApproverID,
  ApplicantID,
  ApprovalStatus,
}: {
  id: string;
  fruitID: string;
  studentName: string;
  ApproverID: string;
  ApplicantID: string;
  ApprovalStatus?: string;
}): Promise<AxiosResponse<T>> => {
  return instance({
    // url: `${baseURL}flows/trigger/a0bffdc4-313e-411f-8133-841bc12c8555`,
    url: `${baseURL}flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb`,
    method: 'post',
    data: {
      id,
      fruitID,
      studentName,
      ApproverID,
      ApplicantID,
      ApprovalStatus,
    },
  });
};

// ydr上架gg到待教列表
export const addGGToStandbyList = <T>({
  id,
  ApproverID,
  fruitID,
  studentName,
  remark,
  gender,
}: {
  id: string;
  ApproverID: string; // 向导葡萄号
  fruitID: string; // gg葡萄id
  studentName: string; // gg名字
  remark: string; // 传入感兴趣的21项
  gender: string; // 性别
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb`,
    method: 'post',
    data: {
      id,
      ApproverID,
      fruitID,
      studentName,
      remark,
      gender,
      ApplicantID: '', // 个教葡萄号
      ApprovalStatus: 'Pending',
      ApprovalType: 'GJ2YDR',
    },
  });
};

// ydr从待教列表撤销上架的gg
export const revokeGGFromStandbyList = <T>({
  id,
  ApproverID,
  fruitID,
  studentName,
}: {
  id: string; // 记录id
  ApproverID: string; // 向导葡萄号
  fruitID: string; // gg葡萄id
  studentName: string; // gg名字
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb`,
    method: 'post',
    data: {
      id,
      ApproverID,
      fruitID,
      studentName,
      ApplicantID: '', // 个教葡萄号
      ApprovalStatus: 'Revoked', // 撤销
      ApprovalType: 'GJ2YDR',
    },
  });
};

// 个教从待教列表申请接单
export const applyGGFromStandbyList = <T>({
  id,
  ApproverID,
  fruitID,
  ApplicantID,
  studentName,
}: {
  id: string; // 记录id
  ApproverID: string; // 向导葡萄号
  fruitID: string; // gg葡萄id
  ApplicantID: string; // 个教葡萄号
  studentName: string; // gg名字
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb`,
    method: 'post',
    data: {
      id,
      ApproverID,
      fruitID,
      ApplicantID,
      studentName,
      ApprovalStatus: 'Pending',
      ApprovalType: 'GJ2YDR',
    },
  });
};

// ydr拒绝个教的待教接单
export const refuseTutorFromStandbyList = <T>({
  id,
  ApproverID,
  fruitID,
  studentName,
}: {
  id: string; // 记录id
  ApproverID: string; // 向导葡萄号
  fruitID: string; // gg葡萄id
  studentName: string; // gg名字
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb`,
    method: 'post',
    data: {
      id,
      ApproverID,
      fruitID,
      studentName,
      ApplicantID: '', // 个教葡萄号置空
      ApprovalStatus: 'Pending',
      ApprovalType: 'GJ2YDR',
    },
  });
};

// ydr接受个教的待教接单
export const acceptTutorFromStandbyList = <T>({
  id,
  ApproverID,
  fruitID,
  ApplicantID,
  studentName,
}: {
  id: string; // 记录id
  ApproverID: string; // 向导葡萄号
  fruitID: string; // gg葡萄id
  ApplicantID: string; // 个教葡萄号
  studentName: string; // gg名字
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb`,
    method: 'post',
    data: {
      id,
      ApproverID,
      fruitID,
      studentName,
      ApplicantID, // 个教葡萄号
      ApprovalStatus: 'Approved',
      ApprovalType: 'GJ2YDR',
    },
  });
};

// 个教查看申请的gg待教列表; id 为空则查询全部
export const getTutorStandbyListById = <T>({ ApplicantID }: { ApplicantID: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?ApplicantID=${ApplicantID}&ApprovalType=GJ2YDR`, // &ApprovalStatus=Pending
    method: 'get',
  });
};

// 向导查看自己上架的gg待教列表;
export const getGuideStandbyListById = <T>({ ApproverID }: { ApproverID: string }): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba?ApproverID=${ApproverID}&ApprovalType=GJ2YDR`, // &ApprovalStatus=Pending
    method: 'get',
  });
};

// 更新学员信息
export const updateStudentInfo = <T>({
  studentID,
  ...fields
}: {
  studentID: string;
  studentName: string;
  studentContact: string;
  studentGender: string;
  studentAge?: string;
  studentJob?: string;
  studentIsMarried?: string;
  studentPresentAddress?: string;
  studentIsPriest?: string;
  ydrID?: string;
  teacherCode?: string;
  L?: string;
  isSeminary?: string;
  actualContact?: string;
  actualContactorName?: string;
  isWeeklyReport?: string;
  studentGrade?: string;
  suggestion?: string;
  studentHS?: string;
  isNeedSpecialApplication?: string;
  specialApplicationNumber?: string;
  specialApplicationContent?: string;
  learnSuggestion?: string;
  suitableClass?: string;
  studentCountry?: string;
  teacherEvaluation?: string;
  teacherContact?: string;
  studentEducational?: string;
  expectTalkTime?: string;
}): Promise<AxiosResponse<T>> => {
  return instance({
    url: `${baseURL}flows/trigger/0090309b-c365-4e37-acab-8e6e7c5ad7d9`,
    method: 'post',
    data: {
      tableName: 'StudentAchives',
      require: {
        studentID,
      },
      fields,
    },
  });
};
