import { allRoutePath } from 'src/routes';
import { useNavigate } from 'react-router-dom';
import { AppContainer, BodyContainer, BottomContainer, StyledGrid, StyledGridItem } from './index.style';
import { useContext, useEffect, useState } from 'react';
import { Badge, TabBar } from 'antd-mobile';
import {
  TextOutline,
  EditSOutline,
  HistogramOutline,
  MailOpenOutline,
  MailOutline,
  PhonebookOutline,
  SearchOutline,
  HeartFill,
  TeamOutline,
  MovieOutline,
  UserOutline,
  FlagOutline,
  LocationOutline,
  StarOutline,
  SetOutline,
  ExclamationShieldFill,
  ClockCircleOutline,
} from 'antd-mobile-icons';
import { HeaderText } from 'src/components/Header';
import LoginPage from 'src/pages/LoginPage/Index';
import { MainAppDataContext } from 'src/context/MainAppDataContext';
import { AUTH_STORE, PODO_STORE, STORAGE_KEYS } from 'src/store';
import SunClound from 'src/components/SunClound';
import { getAppliedStudentListByApproverId, getGuideStandbyListById } from 'src/api/student';
import { isArray } from 'lodash';

const iconSize = 32;
const wordSize = 14;
const tabs = [
  {
    key: 'GUIDE',
    title: '我是向导',
    icon: <LocationOutline fontSize={iconSize} />,
  },
  {
    key: 'TUTOR',
    title: '我是个教',
    icon: <StarOutline fontSize={iconSize} />,
  },
  {
    key: 'GENERAL',
    title: '通用功能',
    icon: <SetOutline fontSize={iconSize} />,
  },
];

type AppliedStudentType = {
  ApplicantID: string; // GJ
  ApprovalStatus: string;
  ApprovalType: string;
  ApproverID: string; // YDR/XD
  date_created: string;
  date_updated: string;
  fruitID: string;
  id: number;
  studentName: string;
  remark: string;
  studentStatus: string;
  currentStatus: string;
};

const GuideTab = (): JSX.Element => {
  const navigate = useNavigate();
  const { userInfo } = useContext(MainAppDataContext);
  const [hasApplicationNews, setHasApplicationNews] = useState(false);
  const [hasStandbyNews, setHasStandbyNews] = useState(false);
  useEffect(() => {
    // 果子待教申请新消息
    async function getGuideStandbyList(ydrID: string) {
      console.log('stand by apply list userInfo: ', userInfo);
      if (ydrID) {
        try {
          const result = await getGuideStandbyListById<Array<AppliedStudentType>>({ ApproverID: ydrID });
          if (result && isArray(result)) {
            // 过滤待审批状态的数据
            const data = result.filter(
              (item) => item.ApprovalType === 'GJ2YDR' && item.ApprovalStatus === 'Pending' && item.ApplicantID,
            );
            if (data.length) {
              setHasStandbyNews(true);
            }
          }
        } catch (error) {
          console.log('get guide standby list: ', error);
        }
      }
    }
    // 收到的 ydr 申请新消息
    async function getAppliedStudentList(ydrID: string) {
      if (ydrID) {
        try {
          const result = await getAppliedStudentListByApproverId<Array<AppliedStudentType>>({
            id: ydrID,
            ApprovalType: 'YDRCHANGE',
          });
          if (result && isArray(result)) {
            // 过滤待审批状态的数据
            const data = result.filter((item) => item.ApprovalStatus === 'Pending');
            if (data.length) {
              setHasApplicationNews(true);
            }
          }
        } catch (error) {
          console.log('get applied ydr list: ', error);
        }
      }
    }
    if (userInfo?.sCode) {
      getGuideStandbyList(userInfo?.sCode);
      getAppliedStudentList(userInfo?.sCode);
    }
  }, [userInfo]);
  return (
    <BodyContainer>
      <StyledGrid
        columns={3}
        gap={8}
        style={{
          width: '100%',
          fontSize: { wordSize } + 'px',
        }}
      >
        <StyledGridItem onClick={() => navigate(allRoutePath.blockListCheck)}>
          <TeamOutline fontSize={iconSize} />
          <div>查重查黑</div>
        </StyledGridItem>
        <StyledGridItem
          onClick={() => navigate(allRoutePath.fruitDetail, { state: { mode: 'create', scodeDisabled: true } })}
        >
          <PhonebookOutline fontSize={iconSize} />
          <div>21 项录入</div>
        </StyledGridItem>
        <StyledGridItem
          onClick={() => navigate(allRoutePath.fruitDetail, { state: { mode: 'create', scodeDisabled: false } })}
        >
          <EditSOutline fontSize={iconSize} />
          <div>21 项代填</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.newPriestSearch)}>
          <SearchOutline fontSize={iconSize} />
          <div>MS 查重</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.priestList)}>
          <UserOutline fontSize={iconSize} />
          <div>MS 录入</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.fruits)}>
          <HeartFill fontSize={iconSize} color="#019650" />
          <div>我的果子</div>
        </StyledGridItem>
        {/* <StyledGridItem onClick={() => navigate(allRoutePath.studentInfoList, { state: { mode: 'view' } })}>
          <BankcardOutline fontSize={iconSize} />
          <div>信卡查看</div>
        </StyledGridItem> */}
        <StyledGridItem onClick={() => navigate(allRoutePath.klassApplyList)}>
          <FlagOutline fontSize={iconSize} />
          <div>大班报名</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.tutorApplyList)}>
          <TextOutline fontSize={iconSize} />
          <div>个教接单申请</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.standbyApplyList)}>
          <ClockCircleOutline fontSize={iconSize} />
          <div>
            果子待教申请
            {hasStandbyNews && <Badge content="新" style={{ marginLeft: '2px', marginBottom: '4px' }} />}
          </div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.ApplicationsSent)}>
          <MailOutline fontSize={iconSize} />
          <div>发出的 YDR 申请</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.IncomingApplications)}>
          <MailOpenOutline fontSize={iconSize} />
          <div>
            收到的 YDR 申请
            {hasApplicationNews && <Badge content="新" style={{ marginLeft: '2px', marginBottom: '4px' }} />}
          </div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.summaryData)}>
          <HistogramOutline fontSize={iconSize} />
          <div>我的做工数据</div>
        </StyledGridItem>
      </StyledGrid>
    </BodyContainer>
  );
};

const TutorTab = (): JSX.Element => {
  const navigate = useNavigate();
  const { userInfo } = useContext(MainAppDataContext);
  const [hasTutorApplyNews, setHasTutorApplyNews] = useState(false);
  useEffect(() => {
    // 个教记录新消息
    async function getAppliedStudentList(ydrID: string) {
      if (ydrID) {
        try {
          const result = await getAppliedStudentListByApproverId<Array<AppliedStudentType>>({
            id: ydrID,
            ApprovalType: 'undefined',
          });
          if (result && isArray(result)) {
            const data = result.filter((item) => item.ApprovalStatus === 'Pending');
            if (data.length) {
              setHasTutorApplyNews(true);
            }
          }
        } catch (error) {
          console.log('get applied tutor list: ', error);
        }
      }
    }
    if (userInfo?.sCode) {
      getAppliedStudentList(userInfo?.sCode);
    }
  }, [userInfo]);
  return (
    <BodyContainer>
      <StyledGrid
        columns={3}
        gap={8}
        style={{
          width: '100%',
          fontSize: { wordSize } + 'px',
        }}
      >
        <StyledGridItem onClick={() => navigate(allRoutePath.studentInfoList, { state: { mode: 'teacher' } })}>
          <PhonebookOutline fontSize={iconSize} />
          <div>信卡填写</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.weeklyReportList)}>
          <TextOutline fontSize={iconSize} />
          <div>周报填写</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.tutorApplyReviewList)}>
          <MovieOutline fontSize={iconSize} />
          <div>
            个教记录
            {hasTutorApplyNews && <Badge content="新" style={{ marginLeft: '2px', marginBottom: '4px' }} />}
          </div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.standbyReviewList)}>
          <ClockCircleOutline fontSize={iconSize} />
          <div>待教列表</div>
        </StyledGridItem>
      </StyledGrid>
    </BodyContainer>
  );
};

const GeneralTab = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <BodyContainer>
      <StyledGrid
        columns={3}
        gap={8}
        style={{
          width: '100%',
          fontSize: { wordSize } + 'px',
        }}
      >
        <StyledGridItem onClick={() => navigate(allRoutePath.sergeantAtArms)}>
          <ExclamationShieldFill color="#f00" fontSize={iconSize} />
          <div>上报 DDZ 信息</div>
        </StyledGridItem>
      </StyledGrid>
    </BodyContainer>
  );
};

const Index = (): JSX.Element => {
  const { userInfo, setMainAppData } = useContext(MainAppDataContext);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('index_tab') || 'GUIDE');
  const setRouteActive = (value: string) => {
    setActiveTab(value);
    localStorage.setItem('index_tab', value);
  };
  useEffect(() => {
    async function init() {
      const LOGIN_TIME = await AUTH_STORE.getItem(STORAGE_KEYS.AUTH.LOGIN_TIME);
      if (!LOGIN_TIME && setMainAppData) {
        setMainAppData({
          userInfo: {
            sCode: '',
            gender: '',
            id: -1,
            name: '',
          },
        });
        setActiveTab('GUIDE');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Scode, '');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Gender, '');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Id, '-1');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Name, '');
      }
      // PODO_STORE.setItem(STORAGE_KEYS.AUTH.LOGIN_TIME, Date.now() / 1000);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBack = () => {
    if (setMainAppData) {
      setMainAppData({
        userInfo: {
          sCode: '',
          gender: '',
          id: -1,
          name: '',
        },
      });
      setActiveTab('GUIDE');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Scode, '');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Gender, '');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Id, '-1');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Name, '');
    }
  };
  // GET_CLASS_LIST
  return userInfo?.sCode ? (
    <AppContainer>
      <HeaderText back="退出登录" onBack={onBack}>
        {activeTab === 'GUIDE' ? '引导人角色菜单' : activeTab === 'TUTOR' ? '教师角色菜单' : '通用功能'}
      </HeaderText>
      <BodyContainer>
        <SunClound />
        {activeTab === 'GUIDE' && <GuideTab />}
        {activeTab === 'TUTOR' && <TutorTab />}
        {activeTab === 'GENERAL' && <GeneralTab />}
        <BottomContainer>
          <TabBar activeKey={activeTab} onChange={(value: string) => setRouteActive(value)} safeArea>
            {tabs.map((item) => (
              <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
          </TabBar>
        </BottomContainer>
      </BodyContainer>
    </AppContainer>
  ) : (
    <LoginPage />
  );
};

export default Index;
