import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Index from 'src/pages/Index/Index';
const StudentList = React.lazy(() => import('../pages/StudentList'));
const ApplyClass = React.lazy(() => import('../pages/ApplyClass'));
const Success = React.lazy(() => import('../pages/Success'));
const NewPriestCreate = React.lazy(() => import('../pages/NewPriestCreate'));
const PriestList = React.lazy(() => import('../pages/PriestList'));
const NewPriestSearch = React.lazy(() => import('../pages/NewPriestSearch/index'));
const SummaryData = React.lazy(() => import('../pages/SummaryData'));
const TutorApply = React.lazy(() => import('../pages/TutorApply'));
const KlassApply = React.lazy(() => import('../pages/KlassApply'));
const KlassApplyList = React.lazy(() => import('../pages/KlassApplyList'));
const TutorApplyList = React.lazy(() => import('../pages/TutorApplyList'));
const TutorApplyReviewList = React.lazy(() => import('../pages/TutorApplyReviewList'));
const StandbyApply = React.lazy(() => import('../pages/StandbyApply'));
const StandbyApplyList = React.lazy(() => import('../pages/StandbyApplyList'));
const StandbyReviewList = React.lazy(() => import('../pages/StandbyReviewList'));
const StudentInfoList = React.lazy(() => import('../pages/StudentInfoList'));
const StudentInfo = React.lazy(() => import('../pages/StudentInfo'));
const WeeklyReportList = React.lazy(() => import('../pages/WeeklyReportList'));
const WeeklyReport = React.lazy(() => import('../pages/WeeklyReport'));
const Fruits = React.lazy(() => import('../pages/Fruits'));
const FruitDetail = React.lazy(() => import('../pages/FruitDetail'));
const BlockListCheck = React.lazy(() => import('../pages/BlockListCheck'));
const Relogin = React.lazy(() => import('../pages/Relogin'));
const ApplicationsSent = React.lazy(() => import('../pages/ApplicationsSent'));
const IncomingApplications = React.lazy(() => import('../pages/IncomingApplications'));
const YDRApplicationsForm = React.lazy(() => import('../pages/YDRApplicationsForm'));
const SergeantAtArms = React.lazy(() => import('../pages/SergeantAtArms'));

export const allRoutePath = {
  index: '/',
  studentList: '/studentList',
  applyClass: '/apply_class',
  success: '/success',
  priestList: '/priest_list',
  newPriestCreate: '/new_priest_create',
  newPriestSearch: '/new_priest_search',
  summaryData: '/summary_data',
  tutorApply: '/new_tutor_apply',
  tutorApplyList: '/tutor_apply_list',
  standbyApply: '/standby_apply',
  standbyApplyList: '/standby_apply_list',
  standbyReviewList: '/standby_review_list',
  studentInfoList: '/student_info_list',
  studentInfo: '/student_info',
  tutorApplyReviewList: '/tutor_review_list',
  weeklyReportList: '/weekly_report_list',
  weeklyReport: '/weekly_report',
  klassApply: '/klass_apply',
  klassApplyList: '/klass_apply_list',
  fruits: '/fruits',
  fruitDetail: '/fruitDetail',
  blockListCheck: '/block_list_check',
  relogin: '/relogin',
  ApplicationsSent: '/applications_sent',
  IncomingApplications: '/incoming_applications',
  YDRApplicationsForm: '/ydr_applications_form',
  sergeantAtArms: '/sergeant_at_arms',
};

const AppWrapperRoute = (): JSX.Element => {
  const routes = [
    {
      exact: true,
      path: allRoutePath.index,
      id: 'index',
      component: Index,
    },
    {
      exact: true,
      path: allRoutePath.relogin,
      id: 'relogin',
      component: Relogin,
    },
    {
      exact: true,
      path: allRoutePath.YDRApplicationsForm,
      id: 'ydrapplicationsform',
      component: YDRApplicationsForm,
    },
    {
      exact: true,
      path: allRoutePath.IncomingApplications,
      id: 'incomingapplications',
      component: IncomingApplications,
    },
    {
      exact: true,
      path: allRoutePath.ApplicationsSent,
      id: 'applicationssent',
      component: ApplicationsSent,
    },
    {
      exact: true,
      path: allRoutePath.fruits,
      id: 'fruits',
      component: Fruits,
    },
    {
      exact: true,
      path: allRoutePath.blockListCheck,
      id: 'blockListCheck',
      component: BlockListCheck,
    },
    {
      exact: true,
      path: allRoutePath.fruitDetail,
      id: 'fruitDetail',
      component: FruitDetail,
    },
    {
      exact: true,
      path: allRoutePath.klassApplyList,
      id: 'klassApplyList',
      component: KlassApplyList,
    },
    {
      exact: true,
      path: allRoutePath.klassApply,
      id: 'klassApply',
      component: KlassApply,
    },
    {
      exact: true,
      path: allRoutePath.weeklyReport,
      id: 'weeklyReport',
      component: WeeklyReport,
    },
    {
      exact: true,
      path: allRoutePath.weeklyReportList,
      id: 'weeklyReportList',
      component: WeeklyReportList,
    },
    {
      exact: true,
      path: allRoutePath.tutorApplyReviewList,
      id: 'tutorApplyReviewList',
      component: TutorApplyReviewList,
    },
    {
      exact: true,
      path: allRoutePath.studentInfo,
      id: 'studentInfo',
      component: StudentInfo,
    },
    {
      exact: true,
      path: allRoutePath.studentInfoList,
      id: 'studentInfoList',
      component: StudentInfoList,
    },
    {
      exact: true,
      path: allRoutePath.standbyReviewList,
      id: 'standbyReviewList',
      component: StandbyReviewList,
    },
    {
      exact: true,
      path: allRoutePath.standbyApplyList,
      id: 'standbyApplyList',
      component: StandbyApplyList,
    },
    {
      exact: true,
      path: allRoutePath.standbyApply,
      id: 'standbyApply',
      component: StandbyApply,
    },
    {
      exact: true,
      path: allRoutePath.tutorApplyList,
      id: 'tutorApplyList',
      component: TutorApplyList,
    },
    {
      exact: true,
      path: allRoutePath.tutorApply,
      id: 'TutorApply',
      component: TutorApply,
    },
    {
      exact: true,
      path: allRoutePath.newPriestSearch,
      id: 'newPriestSearch',
      component: NewPriestSearch,
    },
    {
      exact: true,
      path: allRoutePath.summaryData,
      id: 'summary',
      component: SummaryData,
    },
    {
      exact: true,
      path: allRoutePath.studentList,
      id: 'studentList',
      component: StudentList,
    },
    {
      exact: true,
      path: allRoutePath.newPriestCreate,
      id: 'newPriestCreate',
      component: NewPriestCreate,
    },
    {
      exact: true,
      path: allRoutePath.priestList,
      id: 'priestList',
      component: PriestList,
    },
    {
      exact: true,
      path: allRoutePath.applyClass,
      id: 'applyClass',
      component: ApplyClass,
    },
    {
      exact: true,
      path: allRoutePath.success,
      id: 'success',
      component: Success,
    },
    {
      exact: true,
      path: allRoutePath.sergeantAtArms,
      id: 'sergeantAtArms',
      component: SergeantAtArms,
    },
  ];
  return (
    <>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={<route.component data-test-id={route.id} />} />
        ))}
        <Route>{/* <ErrorPage /> */}</Route>
      </Routes>
    </>
  );
};

export default AppWrapperRoute;
